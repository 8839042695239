<template>
  <div class="Zweispaltig part">
    <div class="constrain">
      <div v-if="isDesktop" class="row-12">
        <div class="col-12 md:col-7 block-container">
          <dynamic :inject="payload.hauptspalte" />
        </div>
        <div class="col-12 md:col-4 md:offset-1 right block-container">
          <dynamic :inject="payload.seitenleiste" />
        </div>
      </div>
      <div v-else class="row-12">
        <div class="col-12 block-container">
          <dynamic :inject="mixed" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';
import { useWithinBreakpoint } from '@/hooks/breakpoints';
import Dynamic from '../structure/Dynamic.vue';

function groupBy(coll, keySelector) {
  const order = [];

  const grouped = coll?.reduce((groups, curr) => {
    const key = keySelector(curr);
    if (!order.includes(key)) {
      order.push(key);
    }
    return {
      ...groups,
      [key]: (groups[key] || []).concat(curr),
    };
  }, {});

  return order.map((o) => grouped[o]);
}

function mix(a = [], b = []) {
  const selector = (comp) => comp.component;
  const groupA = groupBy(a, selector);
  const groupB = groupBy(b, selector);
  const len = Math.min(groupA.length, groupB.length);
  const collected = [];

  for (let i = 0; i < len; i++) {
    collected.push(groupA[i]);
    collected.push(groupB[i]);
  }

  const restAdded = groupA.length === len
    ? collected.concat(groupB.slice(groupA.length))
    : collected.concat(groupA.slice(groupB.length));

  return restAdded.flatMap((g) => g);
}

export default {
  components: { Dynamic },
  props: { payload: { type: Object, default: Object } },
  setup(props) {
    const isDesktop = useWithinBreakpoint('md');
    const mixed = computed(() => mix(props.payload.seitenleiste, props.payload.hauptspalte));
    return {
      mixed,
      isDesktop,
    };
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/_type.scss";

:deep(a) {
  @extend .li2;
}

.right :deep(h3){
  font-size: pt(14);
}

.link {
  @extend .li1;
}
</style>
